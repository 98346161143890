<template>
  <figure class="am-load-block-custom">
    <div class="am-dots">
      <span class="am-dot"></span>
      <span class="am-dot"></span>
      <span class="am-dot"></span>
      <span class="am-dot"></span>
      <span class="am-dot"></span>
      <span class="am-dot"></span>
      <span class="am-dot"></span>
      <span class="am-dot"></span>
    </div>
  </figure>
</template>